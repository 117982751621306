// HTML-type tool tips
export const dataSourceTip = 
`
We use the following 3 data sources: <b>Basic CV data</b>, <b>Tender CVs</b> and <b>Diamonds</b>,
which are used to determine whether information from the CV database, the tender CVs or the diamonds should be included in the search or not.
<br>
<br>
The CV database provides information about work experiences, skills, expertises and industry experiences for the search.
<br>
<br>
The extracts are taken from the CVs used for offers or pitches and are based on a natural language processing model.
<br>
<br>
Only the latest contents of the fields from the Diamonds database that contain a remark are considered for the search. However, the whole remark history is shown in the results.
`
;



export const searchTypeTip = 
`
There are <b>3 types</b> of search options you can choose from:
<ul>
    <li>
        <b><a href="https://learn.microsoft.com/en-us/azure/search/search-lucene-query-architecture" target="_blank" style="color: #003c50;">Full Text Search</a></b>
        matches on plain text stored in the search index. The search engine looks for tokenized strings based on the terms provided in the search query.
        <br>
        <br>
        When matching terms are found, the search engine retrieves documents, ranks them in order of relevance, and returns the top results.
    </li>
    <br>
    <li>
        <b><a href="https://learn.microsoft.com/en-us/azure/search/hybrid-search-overview" target="_blank" style="color: #003c50;">Hybrid Search</a></b>
        is a combination of full text and vector queries that execute against the search index that contains both searchable plain text content and generated embeddings.
        Vector queries execute over numeric representations of content. Because the content is numeric rather than plain text, matching is based on vectors that are most similar to the query vector.
        <br>
        <br>
        The queries are executed in parallel and afterwards merged to create an overall score. 
        <br>
        <br>
        You can choose between:
        <ul>
            <li>
                <b>Hybrid Search without KWE</b> (Keyword-Extraction): 
                <br>
                A normal hybrid search.
            </li>
            <li>
                <b>Hybrid Search with KWE</b> (Keyword-Extraction): 
                <br>
                A hybrid search, where Keywords are extracted from the provided text with the help of ChatGPT.
            </li>
        </ul>
    </li>
</ul>
`
;


export const ragTip = 
`
<b><a href="https://learn.microsoft.com/en-us/azure/search/retrieval-augmented-generation-overview" target="_blank" style="color: #003c50;">Retrieval Augmented Generation</a></b>
(RAG) augments the capabilities of ChatGPT.
<br> 
<br> 
<b>General:</b>
<br> 
RAG architecture means that you can constrain generative AI to your specifically retrieved content sourced from vectorized documents and other data formats.
<br> 
The information retrieved from the search index provides the grounding data that is used by ChatGPT when it formulates a response. 
<br> 
<br> 
<b>Search process:</b>
<br> 
When you type in a query, the search index is searched based on your input. 
Your query is then combined with the matching results (currently the best 20 results according to the score) and sent to ChatGPT using a 
context prompt (can be edited in settings below, if RAG is selected) 
containing explicit instructions for ChatGPT in order to generate a desired answer.
<br>
<br> 
By selecting RAG you get a response generated by ChatGPT (retrieve then read) and you have the option to chat with your results afterwards.
The raw result of the search is also provided.
<br>
<br>
The complete prompt sent to ChatGPT has the following structure:
<ul>
    <li>CONTEXT</li>
    <ul>
        <li>RAG context prompt</li>
    </ul>
    <li>CANDIDATES</li>
    <ul>
        <li>list of found candidate IDs based on the search query with Skills, Industry Experience and Expertise from the data source <i>Basic CV data</i> used as characteristics</li>
    </ul>
    <li>USER QUERY</li>
    <ul>
        <li>keywords/question/chat message</li>
    </ul>
</ul>
<br> 
<b>Recommendation:</b>
<br> 
When activating RAG choose a smaller number (max. 20) for <i>Maximum results</i> in order to not exceed the token limit when passing the candidate results to ChatGPT.
`
;


export const searchModeTip = 
`
<b><a href="https://learn.microsoft.com/en-us/azure/search/search-query-create?tabs=portal-text-query#example-of-a-full-text-query-request" target="_blank" style="color: #003c50;">Search Mode</a></b>
specifies whether matches are based on "All" criteria (favors precision) or "Any" criteria (favors recall) in the query. 
<br> 
<br> 
Make sure to test queries with the <i>Search mode</i> = "Any"|"All" parameter to evaluate the impact of that setting on boolean search.
<br> 
<br> 
When Hybrid Search is selected <i>Search mode</i> = "All" is only applied to full text search which is executed in parallel.
`
;


export const queryTypeTip = 
`
<b><a href="https://learn.microsoft.com/en-us/azure/search/search-query-create?tabs=portal-text-query#example-of-a-full-text-query-request" target="_blank" style="color: #003c50;">Query Type</a></b>
defines the underlying parser.

<ul>
    <li>
        <b><a href="https://learn.microsoft.com/en-us/azure/search/query-simple-syntax" target="_blank" style="color: #003c50;">Simple</a></b>
    </li>
    The simple query parser is optimal for full text search. 
    <br> 
    <br> 
    <li>
    <b><a href="https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax" target="_blank" style="color: #003c50;">Full</a></b>
    </li>
    The full Lucene query parser is for advanced query constructs like regular expressions, proximity search, fuzzy and wildcard search.
    <br> 
    <br> 
    <li>
    <b><a href="https://learn.microsoft.com/en-us/azure/search/semantic-how-to-query-request?tabs=portal-query" target="_blank" style="color: #003c50;">Semantic</a></b>
    </li>
    When selected it invokes a semantic ranking over a result set, promoting the most semantically relevant results to the top of the stack.
</ul>
`
;


export const maxResultsTip = 
`
<b>Maximum Results</b>
returns the specified number of best-matching candidate CVs.
<br>
<br>
When Hybrid Search is selected <i>Maximum Results</i> is also used as number of nearest neighbors to return in vector search. 
`
;


export const availStartDateTip = 
`
<b>Availability start date</b>
is used as filter by selecting candidates with an <i>Availability start date</i> within the selected range.
<br>
<br>
Candidates that did not have an <i>Availability start date</i> were assigned the date '01.01.1999'.
<br>
<br>
You can change the date via text input using the format DD.MM.YYYY. 
<br>
<br>
The default minimum date is set to '01.01.1999' and the default maximum date is set to '31.12.2059', ensuring that no candidates are excluded by default. 
`
;


export const staffingStatusTip = 
`
<b>Staffing status</b>
is used as filter by selecting only candidates that fulfill the checked <i>Staffing status</i>.
<br>
<br>
The default is None. 
`
;


export const staffingPriorityTip = 
`
<b>Staffing priority</b>
is used as filter by selecting only candidates that fulfill the checked <i>Staffing priority</i>.
<br>
<br>
The default is None, ensuring that candidates that do not have a status are also considered for the search. 
`
;


export const orgUnitTip = 
`
<b>Unit</b>
is used as filter by selecting only the organizational units that appear in the selection of <i>Unit</i>.
<br>
<br>
The default includes only the mainline. 
`
;


export const careerLevelTip = 
`
<b>Career level</b>
is used as filter by selecting only candidates that fulfill the checked <i>Career level</i>.
<br>
<br>
The default is None. 
`
;


export const careerTrackTip = 
`
<b>Career track</b>
is used as filter by selecting only candidates that fulfill the checked <i>Career track</i>.
<br>
<br>
The default is None. 
`
;


export const projectLocationDistanceTip = 
`
<b>Project location distance</b>
allows filtering for candidates who are located near the project location. For better results also add the postal code of the project location.
<br>
<br>
The geography points (longitude, latitude) of the project location and the candidate's residence are used to calculate 
the geodesic distance in kilometers and filter the result list according to the selected maximum distance. 
<br>
<br>
The default includes no project location, such that the filter is not applied.
`
;


export const projectAbsencesTip = 
`
<b>Project absences</b>
allows filtering for candidates who are absent for a certain percentage of the whole project duration. Additional metrics like the overlap of absences with the project duration, as well as the
first 30 days after the project start date, are calculated.
<br>
<br>
The absence threshold is used to include only those candidates whose overlap percentage is smaller than or equal to the chosen threshold. For example, choosing 100% does not exclude any candidates,
while choosing 50% excludes all candidates who are absent for more than 50% of the total project duration.
<br>
<br>
The default project start date is set to '01.01.1999', the default project end date is set to '31.12.2059', and the default absence threshold is set to 100%, ensuring that no candidates are excluded by default.  
`
;


export const entryDateAfterTip = 
`
<b>Entry date</b>
is used as filter by selecting candidates with an <i>Entry date</i> that lies after or is equal to the selected date.
<br>
<br>
You can change the date via text input using the format DD.MM.YYYY. 
<br>
<br>
The default date is set to '01.01.1999', ensuring that no candidates are excluded by default. 
`
;

export const furtherFilterTip = 
`
<b>Further Filter</b>
is used to display additional filter options.
`
;

export const lastModifiedDateOlderTip = 
`
<b>Last modified date</b>
is used as filter by selecting candidates with a <i>Last modified date</i> of their CV that is before or equal to the selected date.
<br>
<br>
You can change the date via text input using the format DD.MM.YYYY. 

<br>
<br>
The default date is set to today's date, ensuring that no candidates are excluded by default. 
`
;


export const skillTip = 
`
<b>Query Helper</b>
can be used as an easy way to list relevant industry experience, expertise or skills and their corresponding minimum level.
<br>
<br>
When <i>Query type</i> = "Full" a 
<b><a href="https://learn.microsoft.com/en-us/azure/search/search-query-lucene-examples#example-1-fielded-search" target="_blank" style="color: #003c50;">fielded search</a></b>
is conducted. Otherwise the added skills are simply appended to the query. 
<br>
<br>
The selected skill level represents the minimum level. As such, all of the levels above are added to the query using the OR-operator.
<br>
<br>
<b>Example:</b>
<br>
Skill: "Python", Level: "Good" 
<br> 
<br> leads to 
<br>
<br> 
'("Good knowledge in Python" | "Very good knowledge in Python")'
`
;


export const kweContextPromptTip = 
`
<b>KWE context prompt</b>
enables the editing of the prompt that is sent to ChatGPT in order to extract the keywords.
<br>
<br>
The complete prompt sent to ChatGPT has the following structure:
<ul>
    <li>CONTEXT</li>
    <ul>
        <li>KWE context prompt</li>
    </ul>
    <li>EMPLOYEE KEYWORDS OR QUESTION:</li>
    <ul>
        <li>text</li>
    </ul>
</ul>
`
;


export const ragContextPromptTip = 
`
<b>RAG context prompt</b>
enables the editing of the prompt that is sent to ChatGPT in order to formulate a response based on the search results.
<br>
<br>
The complete prompt sent to ChatGPT has the following structure:
<ul>
    <li>CONTEXT</li>
    <ul>
        <li>RAG context prompt</li>
    </ul>
    <li>CANDIDATES</li>
    <ul>
        <li>list of found candidate IDs based on the search query with Skills, Indutry Experience and Expertise used as characteristics</li>
    </ul>
    <li>USER QUERY</li>
    <ul>
        <li>keywords/question/chat message</li>
    </ul>
</ul>
`
;